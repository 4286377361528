<div *ngIf="this.realItem != null" [class]="this.getItemInfoClasses()">
  <h1 class="info-ueberschrift">
    {{ this.realItem.name + this.getUpgradeLevelString() }}
  </h1>
  <div class="info-body">
    <div class="info-beschreibung">
      {{ this.realItem.beschreibung }}
    </div>
    <div class="info-bonus">
      {{ this.getEquipmentBonusString() }}
    </div>
    <ion-button
      *ngIf="this.actionDisplay != null"
      class="action-button"
      (click)="this.actionClicked()"
    >
      {{ this.actionDisplay }}
    </ion-button>
  </div>
</div>

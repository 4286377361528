import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IonButton } from '@ionic/angular/standalone';
import { ItemDisplayComponent } from 'src/app/ui/components/item-display/item-display.component';
import { Item } from 'src/app/services/items/item';
import { ItemReferenz } from 'src/app/services/items/item-referenz';

@Component({
  selector: 'app-item-info',
  templateUrl: './item-info.component.html',
  styleUrls: ['./item-info.component.scss'],
  standalone: true,
  imports: [CommonModule, ItemDisplayComponent, IonButton],
})
export class ItemInfoComponent implements OnChanges {
  @Input()
  public selectedItem?: ItemReferenz;

  @Input()
  public actionDisplay?: string;

  @Output()
  public actionTaken: EventEmitter<ItemReferenz> =
    new EventEmitter<ItemReferenz>();

  public realItem?: Item;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.realItem = this.selectedItem?.getPublishedItem();
  }

  public actionClicked(): void {
    this.actionTaken.emit(this.selectedItem);
  }

  public getItemInfoClasses(): string[] {
    const classes: string[] = ['info'];

    if (this.selectedItem != null) {
      classes.push(Item.getSeltenheitCssClass(this.selectedItem.seltenheit));
    }

    return classes;
  }

  public getUpgradeLevelString(): string {
    const level: number = this.selectedItem?.upgradeLevel ?? 0;

    if (level == 0) {
      return '';
    }

    return ' (+' + level + ')';
  }

  public getEquipmentBonusString(): string {
    if (this.realItem == null) {
      return '';
    }

    const bonusAdd = this.realItem.getAdditiveEquipmentBonus();
    const bonusMult =
      (this.realItem.getMultiplicativeEquipmentBonus() - 1) * 100;

    let ret: string = '';

    if (bonusAdd != 0) {
      ret +=
        (bonusAdd > 0 ? '+' : '') + bonusAdd.toPrecision(4) + ' Kampfpunkte';
    }

    if (bonusAdd != 0 && bonusMult != 0) {
      ret += ' und ';
    }

    if (bonusMult != 0) {
      ret +=
        (bonusMult > 0 ? '+' : '') + bonusMult.toPrecision(3) + '% Kampfpunkte';
    }

    return ret;
  }
}

<div *ngIf="!this.dummy" [class]="this.getItemBoxClasses()">
  <img
    class="item-icon"
    src="assets/{{ this.realItem.icon }}"
    alt="{{ this.realItem.name }}"
    title="{{ this.realItem.name }}"
  />
  <div class="item-beschriftung">
    <div class="item-anzahl">
      {{ this.realItem.anzahl > 1 ? this.realItem.anzahl : "" }}
    </div>
  </div>
  <div *ngIf="this.isUpIndicatorVisible()" class="item-up-indicator">
    {{ this.getUpIndicatorText() }}
  </div>
</div>

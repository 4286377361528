<app-item-info
  [selectedItem]="this.showSelection ? this.selectedItem : undefined"
  [actionDisplay]="this.actionDisplay"
  (actionTaken)="this.actionTaken.emit($event)"
></app-item-info>

<div class="item-liste">
  <app-item-display
    *ngFor="let item of this.items"
    class="item"
    [item]="item"
    [variant]="shouldRenderSmallDisplays ? 'small' : 'default'"
    [selected]="this.showSelection && item === this.selectedItem"
    [disabled]="this.isItemDisabled(item)"
    (click)="this.itemClicked(item)"
  ></app-item-display>

  <app-item-display
    *ngFor="let item of this.dummys"
    [item]="item"
  ></app-item-display>
</div>

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ItemDisplayComponent } from 'src/app/ui/components/item-display/item-display.component';
import { ItemInfoComponent } from 'src/app/ui/components/item-info/item-info.component';
import { ItemReferenz } from 'src/app/services/items/item-referenz';

@Component({
  selector: 'app-item-liste',
  templateUrl: './item-liste.component.html',
  styleUrls: ['./item-liste.component.scss'],
  standalone: true,
  imports: [CommonModule, ItemDisplayComponent, ItemInfoComponent],
})
export class ItemListeComponent implements OnChanges {
  private static readonly DEFAULT_PLACEHOLDER_COUNT = 12;

  @Input({ required: true })
  public items: ItemReferenz[] = [];

  @Input()
  public selectedItem?: ItemReferenz;

  @Input()
  public actionDisplay?: string;

  @Input()
  public shouldRenderSmallDisplays: boolean = false;

  @Input()
  public showSelection: boolean = true;

  @Input()
  public noItemsText: string = 'Keine Items.';

  @Input()
  public placeholderItemCount = ItemListeComponent.DEFAULT_PLACEHOLDER_COUNT;

  @Input()
  public isItemDisabled: (i: ItemReferenz) => boolean = () => false;

  @Output()
  public itemSelected: EventEmitter<ItemReferenz> =
    new EventEmitter<ItemReferenz>();

  @Output()
  public actionTaken: EventEmitter<ItemReferenz> =
    new EventEmitter<ItemReferenz>();

  private readonly dummyItem = new ItemReferenz();
  public dummys: ItemReferenz[] = [];

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.selectedItem != null &&
      (!this.items.includes(this.selectedItem) ||
        this.isItemDisabled(this.selectedItem))
    ) {
      // Ausgewähltes Item abwählen, wenn sich die Liste ändert und es nicht mehr enthalten ist oder es unwählbar wird
      this.selectedItem = undefined;
      this.itemSelected.emit(this.selectedItem);
    }

    const size = Math.max(this.placeholderItemCount - this.items.length, 0);
    if (size == this.dummys.length) return;
    this.dummys = Array(size).fill(this.dummyItem);
  }

  public itemClicked(target: ItemReferenz): void {
    if (this.isItemDisabled(target)) {
      return;
    }

    if (this.selectedItem != target) {
      this.selectedItem = target;
    } else {
      this.selectedItem = undefined;
    }

    this.itemSelected.emit(this.selectedItem);
  }
}

import { NgIf } from '@angular/common';
import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Item } from 'src/app/services/items/item';
import { ItemReferenz } from 'src/app/services/items/item-referenz';

type ItemDisplayVariants = 'default' | 'small';

@Component({
  selector: 'app-item-display',
  templateUrl: './item-display.component.html',
  styleUrls: ['./item-display.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ItemDisplayComponent implements OnChanges {
  @Input({ required: true })
  public item: ItemReferenz = new ItemReferenz();

  @Input()
  public selected: boolean = false;

  @Input()
  public dummy: boolean = false;

  @HostBinding('class.disabled')
  @Input()
  public disabled: boolean = false;

  @Input()
  public variant: ItemDisplayVariants = 'default';

  @HostBinding('class.small')
  get isSmallVariant() {
    return this.variant === 'small';
  }

  private subscription: Subscription = this.item
    .getItemSubject()
    .subscribe(this.assignNewItem.bind(this));

  public realItem: Item = new Item();

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.subscription.unsubscribe();
    this.subscription = this.item
      .getItemSubject()
      .subscribe(this.assignNewItem.bind(this));
  }

  private assignNewItem(publishedItem: Item): void {
    this.realItem = publishedItem;
  }

  public isUpIndicatorVisible(): boolean {
    return (
      (this.realItem.equipmentStats != null &&
        this.realItem.equipmentStats.upgradeLevel > 0) ||
      this.realItem.sterne > 0
    );
  }

  public getUpIndicatorText(): string {
    let text: string[] = [];

    if (this.realItem.sterne > 0) {
      text.push(this.realItem.sterne + '⭐');
    }

    if (
      this.realItem.equipmentStats != null &&
      this.realItem.equipmentStats.upgradeLevel > 0
    ) {
      text.push('+' + this.realItem.equipmentStats?.upgradeLevel);
    }

    return text.join(' ');
  }

  public getItemBoxClasses(): string[] {
    const classes: string[] = [
      'item-box',
      Item.getSeltenheitCssClass(this.item.seltenheit),
    ];

    if (this.selected) {
      classes.push('selected');
    }

    return classes;
  }
}
